<template>
    <!-- 客户列表 -->
    <div class="culture_container">
        <div class="culture_title">
            <div class="culture_wl_l"></div>
            <div class="culture_t">企业文化</div>
            <div class="culture_wl_r"></div>
        </div>

        <div class="culture_list_container">
            <swiper
                :modules="modules"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                :pagination="{ type: 'bullets' }"
                :effect="'coverflow'"
                :slidesPerView="3"
                :spaceBetween="10"
                :cssMode="true"
            >
                <swiper-slide
                    class="c_img pic"
                    v-for="(img, idx) in imgs"
                    :key="idx"
                    @click="() => show(idx)"
                >
                    <!-- <img src="@/assets/img/culture_1.jpg" alt /> -->
                    <img :src="img.src ? img.src : img" />
                </swiper-slide>
            </swiper>
        </div>
        <vue-easy-lightbox
            :visible="visible"
            :index="index"
            :imgs="imgs"
            @hide="visible = false"
            @on-prev="handlePrev"
            @on-next="handleNext"
        />
    </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/scrollbar/scrollbar.min.css";
import VueEasyLightbox from 'vue-easy-lightbox'
// import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'


export default {
    components: {
        Swiper,
        SwiperSlide,
        VueEasyLightbox,
    },
    data() {
        return {
            imgs: [
                require('@/assets/img/culture/pic1.jpg'),
                require('@/assets/img/culture/pic2.jpg'),
                require('@/assets/img/culture/pic3.jpg'),
                require('@/assets/img/culture/pic4.jpg'),
                require('@/assets/img/culture/pic5.jpg'),
                require('@/assets/img/culture/pic6.jpg'),
                require('@/assets/img/culture/pic7.jpg'),
                require('@/assets/img/culture/pic8.jpg'),
                require('@/assets/img/culture/pic9.jpg'),
                require('@/assets/img/culture/pic10.jpg'),
                require('@/assets/img/culture/pic11.jpg'),
                require('@/assets/img/culture/pic12.jpg'),
                require('@/assets/img/culture/pic13.jpg'),
            ],
            visible: false,
            index: 0 // default
        }
    },
    methods: {
        show(index) {
            this.index = index
            this.visible = true
        },
        handlePrev(oldIndex, newIndex) {
            console.log('when prev btn click or user swipe right ----')
            console.log('oldIndex of imgs:', oldIndex)
            console.log('newIndex of imgs:', newIndex)
        },
        handleNext(oldIndex, newIndex) {
            console.log('when next btn click or user swipe left ----')
            console.log('oldIndex of imgs:', oldIndex)
            console.log('newIndex of imgs:', newIndex)
            if (newIndex === this.imgs.length - 1) {
                this.addImg()
            }
        },
        addImg() {
            this.imgs.push(require('@/assets/img/culture_1.jpg'))
        }
    },
    setup() {

        const onSwiper = (swiper) => {
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };

        onMounted(() => {


        })
        return {
            VueEasyLightbox,
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Autoplay],
        }
    }
}


</script>
 
<style lang="scss">
.culture_container {
    width: 100%;
    height: 540px;
    position: relative;
    // background: #ff9900;
    padding: 50px 0px !important;
    overflow: hidden;

    .culture_title {
        padding-top: 25px;
        display: flex;
        justify-content: center;
        flex-flow: nowrap;
        position: relative;
        align-items: center;
        margin-bottom: 40px;

        .culture_t {
            font-size: 60px;
            font-weight: 700;
            color: #ff9900;
            margin: 0 20px;
        }

        .culture_wl_l {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .culture_wl_r {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }

    .lightbox {
        width: 100%;
        height: 200px;
        position: absolute;
        top: 100px;
        left: 0px;
    }

    .culture_list_container {
        width: 100%;
        height: 100%;
        // background-color: #ff9900;

        .swiper {
            width: 1384px;
            height: 272px;
            margin: 0 auto;
            // margin-top: 60px;
            // background: #ff9900;
            .swiper-wrapper {
                width: 1384px;
                height: 219px;
                position: absolute;
                top: 0px;
                left: 0px;

                .swiper-slide {
                    width: 451px !important;
                    height: 219px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-content: space-around;
                    align-items: center;
                    box-sizing: border-box;
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }

                    .swiper-slide:hover {
                        cursor: pointer;
                    }
                }
            }

            .swiper-pagination,
            .swiper-pagination-bullets,
            .swiper-pagination-horizontal {
                bottom: -100px;
            }
        }
    }
}

// 移动端样式
@media screen and(max-width: 750px) {
    .culture_container {
        width: 100%;
        height: 540px;
        position: relative;
        // background: #ff9900;
        padding: 50px 0px !important;
        overflow: hidden;
    }
}
</style>