<template>
    <!-- 客户列表 -->
    <div class="video_container">
        <div class="video_title">
            <div class="video_wl_l"></div>
            <div class="video_t">信广龙相对论谈话节目</div>
            <div class="video_wl_r"></div>
        </div>

        <div class="video_list_container">
            <div class="video_play_box">
                <video class="video" controls id="video" src="../assets/video/video_1.mp4"></video>
                <!-- <video class="video" controls id="video" :src="videoUrl"></video> -->
                <img class="poster" src="../assets/img/poster.jpg" alt />
                <div class="player_title">《相对论第七期》就业去内地还是北上广深的抉择</div>
                <div class="player_btn_zz">
                    <img
                        @click="player($event)"
                        class="player_btn"
                        id="video_5"
                        src="../assets/img/play.png"
                        alt
                    />
                </div>
            </div>
            <div class="video_list">
                <p class="more_video" @click="toVideo" target="_blank">更多往期精彩>></p>
                <div class="line"></div>
                <div class="v_list">
                    <div class="v_li_1 v_li">
                        <img
                            class="v_poseter"
                            @click="player($event)"
                            id="video_4"
                            src="../assets/img/video/xdl_4.jpg"
                            alt
                        />
                        <div class="v_title">
                            <span>《相对论第四期》</span>
                            <span>假如你是老板</span>
                        </div>
                    </div>
                    <div class="v_li_2 v_li">
                        <img
                            class="v_poseter"
                            @click="player($event)"
                            id="video_3"
                            src="../assets/img/video/xdl_3.jpg"
                            alt
                        />
                        <div class="v_title">
                            <span>《相对论第三期》</span>
                            <span>领导为什么那么虚</span>
                        </div>
                    </div>
                    <div class="v_li_3 v_li">
                        <img
                            class="v_poseter"
                            @click="player($event)"
                            id="video_2"
                            src="../assets/img/video/xdl_2.jpg"
                            alt
                        />
                        <div class="v_title">
                            <span>《相对论第二期》</span>
                            <span>谈谈你了解中的城市性格</span>
                        </div>
                    </div>
                    <div class="v_li_4 v_li">
                        <img
                            class="v_poseter"
                            @click="player($event)"
                            id="video_1"
                            src="../assets/img/video/xdl_5.jpg"
                            alt
                        />
                        <div class="v_title">
                            <span>《相对论第一期》</span>
                            <span>谈谈你心中的年轻</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';
import { gsap } from "gsap/all";

export default {
    components: {

    },
    setup() {

        var videoUrl = ref()

        // 视频播放逻辑
        const player = (e) => {
            videoUrl = require('../assets/video/' + e.currentTarget.id + '.mp4')
            console.log(videoUrl);
            document.getElementById('video').src = videoUrl
            gsap.to('.player_title', { duration: 0.5, autoAlpha: 0, y: 75 })
            gsap.to('.poster,.player_btn_zz', {
                duration: 0.5, autoAlpha: 0, onComplate: () => {
                    document.getElementById('video').play()
                }
            })


        }

        const toVideo = () => {
            location.href = "https://mp.weixin.qq.com/s/KuHl8Q1_-V0Q6uWmwECPaQ"
        }

        return {
            toVideo,
            videoUrl,
            player,
        }
    }
}


</script>
 
<style lang="scss">
.video_container {
    width: 100%;
    height: 1490px;
    position: relative;
    // background: #ff9900;
    // padding: 50px 0px !important;
    background: url(../assets/img/video_c_bg.jpg) no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .video_title {
        padding-top: 120px;
        display: flex;
        justify-content: center;
        flex-flow: nowrap;
        position: relative;
        align-items: center;
        margin-bottom: 40px;

        .video_t {
            font-size: 60px;
            font-weight: 700;
            color: #ff9900;
            margin: 0 20px;
        }

        .video_wl_l {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .video_wl_r {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }

    .video_list_container {
        width: 1387px;
        height: 1127px;
        // background-color: #ff9900;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-around;
        align-items: center;
        box-sizing: border-box;

        .video_play_box {
            width: 1387px;
            height: 780px;
            position: relative;
            top: 0;
            left: 0;
            overflow: hidden;

            .player_btn_zz {
                width: 1387px;
                height: 780px;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba($color: #000000, $alpha: 0.7);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .poster {
                width: 1387px;
                height: 780px;
                position: absolute;
                top: 0;
                left: 0;
            }

            .video {
                width: 1387px;
                height: 780px;
                position: absolute;
                top: 0;
                left: 0;
            }

            .player_title {
                z-index: 99;
                width: 1387px;
                height: 75px;
                // margin-top: -80px;
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: 30px;
                display: flex;
                align-items: center;
                background-image: -moz-linear-gradient(
                    0deg,
                    rgb(255, 255, 255) 33%,
                    rgba(255, 255, 255, 0.9) 39%,
                    rgba(255, 255, 255, 0) 100%
                );
                background-image: -webkit-linear-gradient(
                    0deg,
                    rgb(255, 255, 255) 33%,
                    rgba(255, 255, 255, 0.9) 39%,
                    rgba(255, 255, 255, 0) 100%
                );
                background-image: -ms-linear-gradient(
                    0deg,
                    rgb(255, 255, 255) 33%,
                    rgba(255, 255, 255, 0.9) 39%,
                    rgba(255, 255, 255, 0) 100%
                );
                opacity: 0.902;
            }
        }

        .video_list {
            width: 1387px;
            height: 304px;
            // background-color: #3f3f3f;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-content: space-around;
            align-items: center;
            box-sizing: border-box;
            .more_video {
                color: #fff;
                // width: 176px;
                font-size: 24px;
                width: 100%;
                text-align: right;

                &:hover {
                    color: #ff9900;
                    cursor: pointer;
                }
            }

            .line {
                width: 100%;
                height: 1px;
                background: seashell;
                margin: 10px 0px;
            }

            .v_list {
                width: 1387px;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: space-around;
                align-items: center;
                box-sizing: border-box;
                .v_li {
                    width: 302px;
                    height: 240px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .v_title {
                        color: #ebebeb;
                        font-size: 20px;

                        margin-top: 10px;
                        span {
                            display: block;
                            text-align: center;
                        }
                    }

                    img {
                        width: 100%;
                        height: 169px;
                    }

                    &:hover {
                        color: #ff9900;
                        cursor: pointer;
                        .v_title {
                            color: #ff9900;
                        }
                    }
                }
            }
        }
    }
}
</style>