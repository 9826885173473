<template>
  <div class="home">
    <progress max="100" value="0"></progress>
    <Banner />

    <Introduction />

    <Business />

    <Case />

    <Customer />

    <Culture />

    <Video />
    <!-- <div @click="toLagou" @mouseenter="hoverIn" @mouseout="hoverOut" class="zp_icon"></div> -->
  </div>
</template>

<script>
import { onMounted, onUpdated, onUnmounted, ref } from 'vue'
import Banner from '@/components/Banner.vue';
import Introduction from '@/components/Introduction.vue';
import Business from '@/components/Business.vue';
import Case from '@/components/Case.vue';
import Customer from '@/components/Customer.vue';
import Culture from '@/components/Culture.vue';
import Video from '@/components/Video.vue';
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";

gsap.registerPlugin(gsap, ScrollTrigger, Draggable, MotionPathPlugin);

export default {
  components: {
    Banner,
    Introduction,
    Business,
    Case,
    Customer,
    Culture,
    Video
  },
  setup() {

    const toLagou = () => {
      // location.href = 'https://www.lagou.com/gongsi/v1/j/56745809e01abdf8e73b928636b555ccdaa125974dcbc39a.html'
      window.open('https://www.lagou.com/gongsi/v1/j/56745809e01abdf8e73b928636b555ccdaa125974dcbc39a.html', '_blank')
    }

    const hoverIn = () => {
      gsap.to('.zp_icon', { duration: 1, x: 0, })
    }

    const hoverOut = () => {
      gsap.to('.zp_icon', { duration: 1, x: 90, })
    }
    onMounted(() => {

      // 滚动视差动画
      // 初始化视差
      function init() {
        entryAni()
        businessAni()
        caseAni()
        customerAni()
        cultureAni()
        videoAni()
        footerAni()
      }

      function entryAni() {
        let tl = gsap.timeline({
          delay: 1.2,
          scrollTrigger: {
            trigger: '.who_container',
            scrub: 8.5,
            start: "top bottom", // position of trigger meets the scroller position
            end: "bottom top"
          }
        });
        tl.from('.who_title', {
          // x: 100,
          y: 400,
          ease: 'power4',
          duration: 3
        })
          .from('.who_icon', {
            x: -500,
            opacity: 0,
            ease: 'power4',
            duration: 3
          }, 0.7)
          .from('.w_c_title', {
            y: -100,
            opacity: 0,
            ease: 'power4',
            duration: 3
          }, 0.8)
          .from('.w_c_subTitle', {
            x: -100,
            opacity: 0,
            ease: 'power4',
            duration: 3
          }, 1.2)
          .from('.w_c_c1', {
            // x: -50,
            x: 50,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 1.4)
          .from('.w_c_c2', {
            // x: 50,
            x: 50,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 1.6)
          .from('.w_c_c3', {
            // x: 50,
            x: 50,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 1.8);
      }

      // 业务版块动画
      function businessAni() {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.business_container',
            scrub: 1,
            start: "top bottom", // position of trigger meets the scroller position
            end: "center 40%",
            // markers: true,
          }
        });
        tl.from('.content_content_bg', {
          // x: 100,
          opacity: 0,
          ease: 'power4',
          duration: 0.5
        })
          .from('.content_center_icon', {
            // x: 100,
            scale: 0.5,
            ease: 'power4',
            duration: 0.5
          }, 0.5)
          .from('.b_l_i_1', {
            // x: 100,
            y: 100,
            opacity: 0,
            ease: 'power4',
            duration: 0.5
          }, 0.7)
          .from('.b_l_i_2', {
            y: 100,
            opacity: 0,
            ease: 'power4',
            duration: 0.5
          }, 0.9)
          .from('.b_l_i_3', {
            y: 100,
            opacity: 0,
            ease: 'power4',
            duration: 0.5
          }, 1.2)
          .from('.b_l_i_4', {
            y: 100,
            opacity: 0,
            ease: 'power4',
            duration: 0.5
          }, 1.4)
          .from('.b_l_i_5', {
            y: -100,
            opacity: 0,
            ease: 'power4',
            duration: 0.5
          }, 1.6)
          .from('.b_l_i_6', {
            y: -100,
            opacity: 0,
            ease: 'power4',
            duration: 0.5
          }, 1.8)
          .from('.b_l_i_7', {
            y: -100,
            opacity: 0,
            ease: 'power4',
            duration: 0.5
          }, 2)
      }

      // 案例版块动画
      function caseAni() {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.case_container',
            scrub: 1,
            start: "top bottom", // position of trigger meets the scroller position
            end: "center 40%",
            // markers: true,
          }
        });
        tl.from('.case_title', {
          y: 50,
          opacity: 0,
          ease: 'power2',
          duration: 3
        }, 0.2)
          .from('.swiper-pagination', {
            y: 50,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 0.4)
          .from('.case_wrapper', {
            y: 500,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 0.6)
      }


      // 客户列表版块动画
      function customerAni() {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.customer_container',
            scrub: 1,
            start: "top bottom", // position of trigger meets the scroller position
            end: "center 40%",
            // markers: true,
          }
        });
        tl.from('.customer_title', {
          y: 50,
          opacity: 0,
          ease: 'power2',
          duration: 3
        }, 0.2)
          .from('.customer_list_container', {
            y: 150,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 0.4)
      }

      // 企业文化版块动画
      function cultureAni() {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.culture_container',
            scrub: 1,
            start: "top bottom", // position of trigger meets the scroller position
            end: "center 40%",
            // markers: true,
          }
        });
        tl.from('.culture_title', {
          y: 50,
          opacity: 0,
          ease: 'power2',
          duration: 3
        }, 0.2)
          .from('.culture_list_container', {
            y: 150,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 0.4)
      }

      // 视频板块动画
      function videoAni() {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.video_container',
            scrub: 0.5,
            start: "top bottom", // position of trigger meets the scroller position
            end: "center 40%",
            // markers: true,
          }
        });
        tl.from('.video_title', {
          y: 50,
          opacity: 0,
          ease: 'power2',
          duration: 3
        }, 0.2)
          .from('.video_play_box', {
            y: 150,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 0.4)
          .from('.video_list', {
            y: 150,
            opacity: 0,
            ease: 'power2',
            duration: 3
          }, 1.8)
      }

      // 联系我们版块动画
      function footerAni() {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.footer',
            scrub: 0.5,
            start: "top bottom", // position of trigger meets the scroller position
            end: "center 40%",
            // markers: true,
          }
        });
        tl.from('.footer_title', {
          y: 50,
          opacity: 0,
          ease: 'power2',
          duration: 3
        }, 0.2)

      }

      // gsap.from('.zp_icon', { duration: 2, opacity: 0, y: 155, delay: 1, })
      // gsap.to('.zp_icon', { duration: 2, y: 90, delay: 3, })






      // init()





      // 顶部进度条动画
      gsap.to('progress', {
        value: 100,
        ease: 'none',
        scrollTrigger: { scrub: 0.3 }
      });
    })



    return {
      toLagou,
      hoverIn,
      hoverOut
    }


  }

}



</script>

<style lang="scss" scoped>
/* 在屏幕上， 并且 最大的宽度是 1200px 可以设置想要的样式 */
@media screen and (min-width: 1200px) {
}

/* 在屏幕上， 并且 最大的宽度是 500px 可以设置想要的样式 */
@media screen and (max-width: 750px) {
}

.zp_icon {
  background: url(../assets/img/zp_icon.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 80px;
  right: 0;
  width: 155px;
  height: 96.66px;
  z-index: 99;
  cursor: pointer;
  &.zp_icon:hover {
    // width: 300px;
  }
}

progress {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  border: none;
  background: transparent;
  z-index: 99;
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  background: linear-gradient(
    to left,
    #db38b5,
    #01b3e3,
    #25ce7b,
    #fdc741,
    #ff6b01,
    #fc4236
  );
  background-attachment: fixed;
}

.home {
  font-family: "SourceHanSansCN-Regular";
  width: 100%;
  overflow-x: hidden;
  .part {
    width: 100%;
  }

  // 头部banner
  .introduce_container {
    height: 885px;
    background: url(../assets/img/bannerbg.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

// 移动端样式
@media screen and (min-width: 320px) and(max-width: 750px) {
  progress {
    height: 15px;
  }
}
</style>
