<template>
    <div class="who_container part">
        <div class="who_title">
            <div class="who_wl_l"></div>
            <div class="who_t">我们是谁</div>
            <div class="who_wl_r"></div>
        </div>
        <div class="who_content">
            <p class="w_c_title">企业文化</p>
            <div class="w_c_subTitle">
                <span>THINKING释义：</span>专业思考 专业见解
            </div>
            <ul class="w_c_context">
                <li class="w_c_c1">
                    <span>企业口号：</span> 信广龙 行而有信
                </li>
                <li class="w_c_c2">
                    <span>企业文化：</span>专业 激情 效率 诚信
                </li>
                <li class="w_c_c3">
                    <span>价值观：</span>客户至上 结果导向 专业敬业 诚信为本
                </li>
            </ul>
        </div>
        <div class="who_icon">
            <span class="w_ip">信广龙IP</span>
            <span class="w_ip_name">爪斯特</span>
        </div>
        <div class="who_icon_m">
            <span class="w_ip">信广龙IP</span>
            <span class="w_ip_name">爪斯特</span>
        </div>
    </div>
</template>

<script>



</script>
 
<style lang="scss" scoped>
// 我是谁
.who_container {
    width: 100%;
    height: 885px;
    background: #ededed;
    position: relative;
    box-sizing: border-box;

    .who_title {
        padding-top: 125px;
        display: flex;
        justify-content: center;
        flex-flow: nowrap;
        position: relative;
        align-items: center;

        .who_t {
            font-size: 40px;
            font-weight: 700;
            color: #ff9900;
            margin: 0 20px;
        }

        .who_wl_l {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .who_wl_r {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }

    .who_content {
        width: 890px;
        position: absolute;
        top: 315px;
        left: 960px;

        .w_c_title {
            font-size: 54px;
            font-weight: 700;
            padding-left: 20px;
            margin-bottom: 20px;
        }

        .w_c_subTitle {
            width: 721px;
            height: 68px;
            background: url(../assets/img/sub_t_bg.png);
            background-size: 100%;
            background-repeat: no-repeat;
            font-size: 40px;
            color: #fff;
            display: flex;
            justify-content: start;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: 10px;
            letter-spacing: 2px;

            span {
                font-weight: 700;
            }
        }

        .w_c_context {
            padding-left: 50px;

            .w_c_c1,
            .w_c_c2,
            .w_c_c3 {
                font-size: 32.5px;
                padding: 10px 0px;
                span {
                    width: 185px;
                    display: inline-block;
                    font-weight: 700;
                    text-align: justify;
                }
            }
        }
    }

    .who_icon_m {
        display: none;
    }

    .who_icon {
        width: 765px;
        height: 815px;
        position: absolute;
        left: 139px;
        top: 125px;
        background: url(../assets/img/p2_icon.png);
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: 99;

        span {
            display: block;
        }

        .w_ip {
            font-size: 30px;
            position: absolute;
            top: 440px;
            left: 0px;
        }
        .w_ip_name {
            font-size: 44px;
            color: #ff9900;
            font-weight: 700;
            position: absolute;
            top: 487px;
            left: 0px;
        }
    }
}

// 移动端样式
@media screen and (min-width: 320px) and(max-width: 750px) {
    .who_container {
        height: 969px;
        overflow: hidden;

        .who_icon {
            display: none;
        }
        .who_icon_m {
            display: block;
            width: 580px;
            height: 614px;
            // display: none;
            position: absolute;
            top: 330px;
            right: 118px;
            background: url(../assets/img/who_icon_m.png);
            background-size: 100%;
            background-repeat: no-repeat;

            .w_ip {
                font-size: 32px;
                position: absolute;
                top: 0;
                right: -80px;
            }

            .w_ip_name {
                font-size: 48px;
                color: #ff9900;
                position: absolute;
                top: 100px;
                right: -80px;
            }
        }
        .who_title {
            padding-top: 80px;
            display: flex;
            justify-content: center;
            flex-flow: nowrap;
            position: relative;
            align-items: center;

            .who_t {
                font-size: 76px;
                font-weight: 700;
                color: #ff9900;
                margin: 0 20px;
            }

            .who_wl_l {
                width: 123px;
                height: 31px;
                background: url(../assets/img/p2_title_wl.png);
                background-size: 100%;
                background-repeat: no-repeat;
            }

            .who_wl_r {
                width: 123px;
                height: 31px;
                background: url(../assets/img/p2_title_wl.png);
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }

        .who_content {
            width: 1515px;
            height: 900px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            position: absolute;
            top: 195px;
            left: 88px;

            .w_c_title {
                font-size: 76px;
            }

            .w_c_subTitle {
                width: 1018px;
                height: 97px;
                font-size: 50px;
                margin: 0;
                padding: 0;
                display: block;
                letter-spacing: 0px;
            }

            .w_c_context {
                width: 100%;
                padding: 0;
                padding-left: 60px;
                // font-size: 36px;
                letter-spacing: 0px;

                .w_c_c1,
                .w_c_c2,
                .w_c_c3 {
                    font-size: 46px !important;
                    padding: 20px 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    color: #383636;
                    span {
                        color: #000;
                        width: 300px;
                        display: list-item;
                        font-weight: 700;
                        text-align: justify;
                    }
                }
            }
        }
    }
}
</style>