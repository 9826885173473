<template>
    <!-- 案例列表 -->
    <div class="case_container">
        <div class="case_title">
            <div class="case_wl_l"></div>
            <div class="case_t">案例展示</div>
            <div class="case_wl_r"></div>
        </div>
        <!-- 各案例种类列表 -->
        <div class="case_list_container">
            <!-- 分页器 -->
            <div class="case_wrapper">
                <swiper
                    :modules="modules"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    :pagination="pagination"
                    :autoplay="{
                        'delay': 62500,
                        'disableOnInteraction': false
                    }"
                    :effect="'coverflow'"
                    :cssMode="true"
                >
                    <swiper-slide>
                        <div class="case_brand">
                            <div class="case_li" v-for="item in case_brand" :key="item.id">
                                <img :src="item.posterUrl" alt />
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="case_brand">
                            <div
                                class="case_li"
                                v-for="item in case_public_relations"
                                :key="item.id"
                            >
                                <img :src="item.posterUrl" alt />
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="case_brand">
                            <div class="case_li" v-for="item in case_marketing" :key="item.id">
                                <img :src="item.posterUrl" alt />
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="case_brand">
                            <div class="case_li" v-for="item in case_video" :key="item.id">
                                <img :src="item.posterUrl" alt />
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="case_brand">
                            <div class="case_li" v-for="item in case_interaction" :key="item.id">
                                <img :src="item.posterUrl" alt />
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <!-- 展示区 -->
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/scrollbar/scrollbar.min.css";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {

        const caseListType = reactive([
            "品牌营销",
            "公关传媒",
            "活动营销",
            "视频营销",
            "互动开发"
        ])

        const prePage = ref(1)

        // 品牌营销
        const case_brand = reactive([
            {
                id: 1,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/brand/case_1.jpg'),
            },
            {
                id: 2,
                title: '99公益日',
                posterUrl: require('../assets/img/brand/case_2.jpg'),

            },
            {
                id: 3,
                title: '99公益日3',
                posterUrl: require('../assets/img/brand/case_3.jpg'),

            },
            {
                id: 4,
                title: '99公益日4',
                posterUrl: require('../assets/img/brand/case_4.jpg'),

            },
            {
                id: 5,
                title: '99公益日5',
                posterUrl: require('../assets/img/brand/case_5.jpg'),

            },
            {
                id: 6,
                title: '99公益日6',
                posterUrl: require('../assets/img/brand/case_6.jpg'),

            }
        ])

        // 公关传媒
        const case_public_relations = reactive([
            {
                id: 1,
                title: '公关1',
                posterUrl: require('../assets/img/public-relations/case_1.jpg'),
            },
            {
                id: 2,
                title: '公关2',
                posterUrl: require('../assets/img/public-relations/case_2.jpg'),

            },
            {
                id: 3,
                title: '公关3',
                posterUrl: require('../assets/img/public-relations/case_3.jpg'),

            },
        ])

        // 活动营销
        const case_marketing = reactive([
            {
                id: 1,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/marketing/case_1.jpg'),
            },
            {
                id: 2,
                title: '99公益日',
                posterUrl: require('../assets/img/marketing/case_2.jpg'),

            },
            {
                id: 3,
                title: '99公益日3',
                posterUrl: require('../assets/img/marketing/case_3.jpg'),

            },
            {
                id: 4,
                title: '99公益日4',
                posterUrl: require('../assets/img/marketing/case_4.jpg'),

            },
            {
                id: 5,
                title: '99公益日5',
                posterUrl: require('../assets/img/marketing/case_5.jpg'),

            },
            {
                id: 6,
                title: '99公益日6',
                posterUrl: require('../assets/img/marketing/case_6.jpg'),

            }
        ])

        // 视频营销
        const case_video = reactive([
            {
                id: 1,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/video/case_1.jpg'),
            },
            {
                id: 2,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/video/case_2.jpg'),
            },
            {
                id: 3,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/video/case_3.jpg'),
            },
            {
                id: 4,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/video/case_4.jpg'),
            },
            {
                id: 5,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/video/case_5.jpg'),
            },
            {
                id: 6,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/video/case_6.jpg'),
            },
        ])


        // 互动开发
        const case_interaction = reactive([
            {
                id: 1,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/interaction/case_1.jpg'),
            },
            {
                id: 2,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/interaction/case_2.jpg'),
            },
            {
                id: 3,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/interaction/case_3.jpg'),
            },
            {
                id: 4,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/interaction/case_4.jpg'),
            },
            {
                id: 5,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/interaction/case_5.jpg'),
            },
            {
                id: 6,
                title: '立白·百里挑一',
                posterUrl: require('../assets/img/interaction/case_6.jpg'),
            },
        ])


        const isPhone = () => {
            if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
                console.log("isPhone");
            } else {
                console.log("isPC");

            }
        }
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            prePage.value = 2
            case_brand.splice(2, 4)
        } else {
            prePage.value = 6


        }


        // 定义分页器样式
        const pagination = reactive({
            "clickable": true,
            "renderBullet": function (index, className) {
                return '<span class=\"' + className + '\">' + (caseListType[index]) + '</span>';
            }
        })

        const onSwiper = (swiper) => {
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };
        return {
            prePage,
            isPhone,
            case_brand,
            case_public_relations,
            case_marketing,
            case_video,
            case_interaction,
            onSwiper,
            onSlideChange,
            pagination,
            modules: [Navigation, Pagination, Autoplay],
        };
    },
};


</script>
 
<style lang="scss">
.case_container {
    width: 100%;
    height: 1070px;
    position: relative;
    background: #fff;
    overflow: hidden;
    // font-family: "SourceHanSansCN-Regular";

    .case_title {
        padding-top: 125px;
        display: flex;
        justify-content: center;
        flex-flow: nowrap;
        position: relative;
        align-items: center;

        .case_t {
            font-size: 60px;
            font-weight: 700;
            color: #ff9900;
            margin: 0 20px;
        }

        .case_wl_l {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .case_wl_r {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }

    .case_list_container {
        width: 1657px;
        height: 740px;
        margin: 0 auto;

        .case_brand {
            width: 1657px;
            height: 622px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 30px;
            .case_li {
                width: 521px;
                height: 290px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .case_li:hover {
                cursor: pointer;
            }
        }

        .swiper {
            width: 1657px;
            height: 740px;
            .swiper-wrapper {
                width: 1657px;
                height: 622px;
                position: absolute;
                top: 120px;
                left: 0;
            }
        }

        .swiper-pagination,
        .swiper-pagination-clickable,
        .swiper-pagination-bullets,
        .swiper-pagination-horizontal {
            position: absolute;
            width: 1056px;
            height: 53px;
            top: 60px;
            left: 300px;
            // margin: 0;
        }
        .swiper-pagination-bullet {
            padding: 5px 10px;
            border-radius: 0;
            width: auto;
            height: 30px;
            text-align: center;
            line-height: 30px;
            font-size: 40px;
            color: #6f6f6f;
            opacity: 1;
            background: rgba(0, 0, 0, 0);
        }
        .swiper-pagination-bullet-active {
            color: #ff9900;
            font-size: 40px;
            // background: #007aff;
            border-bottom: 4px solid #ff9900;
            padding-bottom: 40px;
        }
    }
}

// 移动端样式
@media screen and(max-width: 750px) {
    .case_container {
        width: 100%;
        height: 2143px;
        position: relative;
        background: #fff;
        overflow: hidden;
        .case_title {
            padding-top: 125px;
            display: flex;
            justify-content: center;
            flex-flow: nowrap;
            position: relative;
            align-items: center;

            .case_t {
                font-size: 76px;
                font-weight: 700;
                color: #ff9900;
                margin: 0 20px;
            }

            .case_wl_l {
                width: 135px;
                height: 37px;
                background: url(../assets/img/p2_title_wl.png);
                background-size: 100%;
                background-repeat: no-repeat;
            }

            .case_wl_r {
                width: 135px;
                height: 37px;
                background: url(../assets/img/p2_title_wl.png);
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }

        .case_list_container {
            width: 1607px;
            height: 1599px;
            position: absolute;
            top: 430px;
            left: 158px;

            .case_wrapper {
                width: 1607px;
                height: 1599px;
                position: absolute;
                top: 0px;
                left: 0px;

                .swiper {
                    width: 1607px;
                    height: 1599px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }

                .swiper-pagination {
                    width: 1607px;
                    height: 85px;
                    position: absolute;
                    top: -130px;
                    left: 0;

                    .swiper-pagination-bullet {
                        font-style: 61px;
                        font-weight: 700;
                    }

                    .swiper-pagination-bullet-active {
                        border-bottom: 15px solid #ff9900;
                        border-radius: 10px;
                        padding-bottom: 60px;
                    }
                }

                .swiper-wrapper {
                    width: 1607px;
                    height: 1443px;
                    position: absolute;
                    top: 156px;
                    left: 0;

                    .swiper-slide {
                        width: 1607px;
                        height: 1443px;

                        .case_brand {
                            .case_li {
                                width: 100%;
                                height: 683px;
                            }

                            .case_li:nth-child(1) {
                                margin-bottom: 78px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>