<template>
    <!-- 顶部banner -->
    <div class="banner_container">
        <swiper
            :modules="modules"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :pagination="{ clickable: true }"
            :autoplay="{
                'delay': 2500,
                'disableOnInteraction': false
            }"
            :effect="'coverflow'"
            :cssMode="true"
        >
            <swiper-slide>
                <img src="@/assets/img/banner_1.jpg" alt />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/img/banner_2.jpg" alt />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/img/banner_3.jpg" alt />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";


export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {

        let swiperOptions = reactive({
            autoplay: {
                delay: 500,
            },
        })

        const onSwiper = (swiper) => {
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Autoplay],
        };
    },
};

</script>
 
<style lang="scss" scoped>
.banner_container {
    width: 100%;
    height: 885px;

    img {
        width: 100%;
    }
}

.swiper-pagination-bullet {
    background: rgb(255, 255, 255);
}
.swiper-pagination-bullet-active {
    background: rgb(255, 153, 0) !important;
}
</style>