<template>
    <!-- 业务范畴 -->
    <div class="business_container">
        <div class="business_content">
            <div class="content_content_bg">
                <div class="content_center_icon">
                    <span>我们能</span>
                    <span>做些什么</span>
                </div>
            </div>
            <div class="business_list">
                <div class="b_l_i_1">
                    <span class="b_i_1 b_i"></span>
                    <div class="b_i_1_con">
                        <span class="b_c_1_title">传播类</span>
                        <span class="b_c_1_t">ERP传播、公关传播广告投放</span>
                    </div>
                </div>
                <div class="b_l_i_2">
                    <span class="b_i_2 b_i"></span>
                    <div class="b_i_2_con">
                        <span class="b_c_2_title">技术类</span>
                        <span class="b_c_2_t">H5互动开发、小程序软件开发配套营销推广程序开发</span>
                    </div>
                </div>
                <div class="b_l_i_3">
                    <span class="b_i_3 b_i"></span>
                    <div class="b_i_3_con">
                        <span class="b_c_3_title">推广类</span>
                        <span class="b_c_3_t">品牌活动推广 产品上市推广</span>
                    </div>
                </div>
                <div class="b_l_i_4">
                    <span class="b_i_4 b_i"></span>
                    <div class="b_i_4_con">
                        <span class="b_c_4_title">视频类</span>
                        <span class="b_c_4_t">视频创作拍摄 3D视频</span>
                    </div>
                </div>
                <div class="b_l_i_5">
                    <div class="b_i_5_con">
                        <span class="b_c_5_title">活动类</span>
                        <span class="b_c_5_t">新闻、论坛发布会 营销活动地面执行</span>
                    </div>
                    <span class="b_i_5 b_i"></span>
                </div>
                <div class="b_l_i_6">
                    <div class="b_i_6_con">
                        <span class="b_c_6_title">品牌类</span>
                        <span class="b_c_6_t">品牌策略规划 品牌诊断分析</span>
                    </div>
                    <span class="b_i_6 b_i"></span>
                </div>
                <div class="b_l_i_7">
                    <div class="b_i_7_con">
                        <span class="b_c_7_title">创意类</span>
                        <span class="b_c_7_t">广告创意设计、移动端页面设计 手绘漫画设计</span>
                    </div>
                    <span class="b_i_7 b_i"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



</script>
 
<style lang="scss" scoped>
// PC端样式
@media screen and (min-width: 768px) {
    .business_container {
        width: 100%;
        height: 1290px;
        position: relative;
        background: #fff;
        overflow: hidden;

        .business_content {
            width: 1540px;
            height: 1004px;
            position: relative;
            top: 110px;
            left: 178px;

            .content_content_bg {
                width: 1004px;
                height: 1004px;
                background: url(../assets/img/p2_circle_bg.png);
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 268px;

                .content_center_icon {
                    width: 254px;
                    height: 254px;
                    background: url(../assets/img/p2_circle.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 375px;
                    left: 375px;
                    color: #fff;
                    font-size: 46px;
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    flex-wrap: wrap;
                }
            }

            .business_list {
                position: absolute;
                top: 0px;
                left: 0px;

                .b_l_i_1 {
                    width: 337px;
                    height: 127px;
                    position: absolute;
                    top: 103px;
                    left: 681px;
                    .b_i_1 {
                        width: 65px;
                        height: 65px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_2.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_1_con {
                        width: 251px;
                        height: 113px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 85px;

                        .b_c_1_title {
                            color: #ff9900;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_1_t {
                            font-size: 26px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_2 {
                    width: 459px;
                    height: 130px;
                    position: absolute;
                    top: 289px;
                    left: 1081px;
                    .b_i_2 {
                        width: 65px;
                        height: 66px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_3.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_2_con {
                        width: 377px;
                        height: 116px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 85px;

                        .b_c_2_title {
                            color: #ff9900;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_2_t {
                            font-size: 26px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_3 {
                    width: 459px;
                    height: 130px;
                    position: absolute;
                    top: 562px;
                    left: 1106px;
                    .b_i_3 {
                        width: 65px;
                        height: 66px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_4.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_3_con {
                        width: 168px;
                        height: 112px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 85px;

                        .b_c_3_title {
                            color: #ff9900;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_3_t {
                            font-size: 26px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_4 {
                    width: 459px;
                    height: 130px;
                    position: absolute;
                    top: 799px;
                    left: 921px;
                    .b_i_4 {
                        width: 65px;
                        height: 66px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_5.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_4_con {
                        width: 168px;
                        height: 112px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 85px;

                        .b_c_4_title {
                            color: #ff9900;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_4_t {
                            font-size: 26px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_5 {
                    width: 308px;
                    height: 129px;
                    position: absolute;
                    top: 799px;
                    left: 335px;
                    .b_i_5 {
                        width: 65px;
                        height: 66px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: url(../assets/img/icon_6.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_5_con {
                        width: 221px;
                        height: 112px;
                        display: flex;
                        justify-content: end;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        right: 85px;

                        .b_c_5_title {
                            color: #ff9900;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_5_t {
                            font-size: 26px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_6 {
                    width: 249px;
                    height: 127px;
                    position: absolute;
                    top: 563px;
                    left: 201px;
                    .b_i_6 {
                        width: 65px;
                        height: 66px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: url(../assets/img/icon_7.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_6_con {
                        width: 163px;
                        height: 114px;
                        display: flex;
                        justify-content: end;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        right: 85px;

                        .b_c_6_title {
                            color: #ff9900;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_6_t {
                            font-size: 26px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_7 {
                    width: 480px;
                    height: 129px;
                    position: absolute;
                    top: 290px;
                    left: 0px;
                    .b_i_7 {
                        width: 65px;
                        height: 66px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: url(../assets/img/icon_1.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_7_con {
                        width: 388px;
                        height: 114px;
                        display: flex;
                        justify-content: end;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        right: 85px;

                        .b_c_7_title {
                            color: #ff9900;
                            font-size: 40px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_7_t {
                            font-size: 26px;
                            color: #383636;
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
}

// 移动端样式
@media screen and (min-width: 320px) and(max-width: 750px) {
    .business_container {
        width: 100%;
        height: 1600px;
        position: relative;
        background: #fff;
        overflow: hidden;

        .business_content {
            width: 1670px;
            height: 1217px;
            position: relative;
            top: 350px;
            left: 192px;

            .content_content_bg {
                width: 1138px;
                height: 1138px;
                background: url(../assets/img/p2_circle_bg.png);
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 24px;
                left: 199px;

                .content_center_icon {
                    width: 349px;
                    height: 350px;
                    background: url(../assets/img/p2_circle.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 395px;
                    left: 395px;
                    color: #fff;
                    font-size: 46px;
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    flex-wrap: wrap;
                }
            }

            .business_list {
                width: 1670px;
                height: 1217px;
                position: absolute;
                top: 0px;
                left: 0px;

                .b_l_i_1 {
                    width: 542px;
                    height: 189px;
                    position: absolute;
                    top: 0px;
                    left: 681px;
                    .b_i_1 {
                        width: 97px;
                        height: 97px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_2.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_1_con {
                        width: 530px;
                        height: 168px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 132px;

                        .b_c_1_title {
                            color: #ff9900;
                            font-size: 56px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_1_t {
                            font-size: 46px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_2 {
                    width: 590px;
                    height: 248px;
                    position: absolute;
                    top: 283px;
                    left: 1081px;
                    .b_i_2 {
                        width: 97px;
                        height: 97px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_3.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_2_con {
                        width: 468px;
                        height: 228px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 132px;

                        .b_c_2_title {
                            color: #ff9900;
                            font-size: 56px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_2_t {
                            font-size: 46px;
                            color: #383636;
                        }
                    }
                }
                .b_l_i_3 {
                    width: 408px;
                    height: 194px;
                    position: absolute;
                    top: 730px;
                    left: 1127px;
                    .b_i_3 {
                        width: 97px;
                        height: 97px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_4.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_3_con {
                        width: 468px;
                        height: 228px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 132px;

                        .b_c_3_title {
                            color: #ff9900;
                            font-size: 56px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_3_t {
                            font-size: 46px;
                            color: #383636;
                        }
                    }
                }

                .b_l_i_4 {
                    width: 402px;
                    height: 197px;
                    position: absolute;
                    top: 1044px;
                    left: 997px;
                    .b_i_4 {
                        width: 97px;
                        height: 97px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: url(../assets/img/icon_5.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_4_con {
                        width: 468px;
                        height: 228px;
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        left: 132px;

                        .b_c_4_title {
                            color: #ff9900;
                            font-size: 56px;
                            font-weight: 700;
                            letter-spacing: 5px;
                        }

                        .b_c_4_t {
                            font-size: 46px;
                            color: #383636;
                        }
                    }
                }
                .b_l_i_5 {
                    width: 497px;
                    height: 197px;
                    position: absolute;
                    top: 1044px;
                    left: 43px;
                    .b_i_5 {
                        width: 97px;
                        height: 97px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: url(../assets/img/icon_6.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_5_con {
                        width: 468px;
                        height: 228px;
                        display: flex;
                        justify-content: right;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        right: 132px;

                        .b_c_5_title {
                            color: #ff9900;
                            font-size: 56px;
                            font-weight: 700;
                            letter-spacing: 5px;
                            text-align: right;
                            width: 100%;
                        }

                        .b_c_5_t {
                            font-size: 46px;
                            color: #383636;
                            width: 100%;
                        }
                    }
                }
                .b_l_i_6 {
                    width: 400px;
                    height: 194px;
                    position: absolute;
                    top: 730px;
                    left: 10px;
                    .b_i_6 {
                        width: 97px;
                        height: 97px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: url(../assets/img/icon_7.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_6_con {
                        width: 468px;
                        height: 228px;
                        display: flex;
                        justify-content: right;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        right: 132px;

                        .b_c_6_title {
                            color: #ff9900;
                            font-size: 56px;
                            font-weight: 700;
                            letter-spacing: 5px;
                            text-align: right;
                            width: 100%;
                        }

                        .b_c_6_t {
                            font-size: 46px;
                            color: #383636;
                            text-align: right;
                        }
                    }
                }
                .b_l_i_7 {
                    width: 459px;
                    height: 249px;
                    position: absolute;
                    top: 279px;
                    left: 0px;
                    .b_i_7 {
                        width: 97px;
                        height: 97px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: url(../assets/img/icon_1.png);
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .b_i_7_con {
                        width: 468px;
                        height: 228px;
                        display: flex;
                        justify-content: right;
                        flex-wrap: wrap;
                        position: absolute;
                        align-content: baseline;
                        top: 0;
                        right: 132px;

                        .b_c_7_title {
                            color: #ff9900;
                            font-size: 56px;
                            font-weight: 700;
                            letter-spacing: 5px;
                            text-align: right;
                            width: 100%;
                        }

                        .b_c_7_t {
                            font-size: 46px;
                            color: #383636;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
</style>