<template>
    <!-- 客户列表 -->
    <div class="customer_container">
        <div class="customer_title">
            <div class="customer_wl_l"></div>
            <div class="customer_t">服务客户</div>
            <div class="customer_wl_r"></div>
        </div>

        <div class="customer_list_container">
            <swiper
                :modules="modules"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                :pagination="{ type: 'bullets' }"
                :autoplay="{
                    'delay': 3000,
                    'disableOnInteraction': false
                }"
                :effect="'coverflow'"
                :cssMode="true"
            >
                <swiper-slide>
                    <img src="@/assets/img/logo/logo_1.png" alt />
                </swiper-slide>
                <swiper-slide>
                    <img src="@/assets/img/logo/logo_2.png" alt />
                </swiper-slide>
                <swiper-slide>
                    <img src="@/assets/img/logo/logo_3.png" alt />
                </swiper-slide>
                <swiper-slide>
                    <img src="@/assets/img/logo/logo_4.png" alt />
                </swiper-slide>
                <swiper-slide>
                    <img src="@/assets/img/logo/logo_5.png" alt />
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Navigation, Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/scrollbar/scrollbar.min.css";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {

        const onSwiper = (swiper) => {
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };

        // 定义分页器样式
        // const pagination = reactive({
        //     "clickable": true,
        //     "renderBullet": function (index, className) {
        //         return '<span class=\"' + className + '\">' + index + '</span>';
        //     }
        // })

        return {
            onSwiper,
            onSlideChange,
            // pagination,
            modules: [Navigation, Pagination, Autoplay],

        }
    }
}


</script>
 
<style lang="scss">
.customer_container {
    width: 100%;
    height: 680px;
    position: relative;
    background: #fff;
    overflow: hidden;

    .customer_title {
        // padding-top: 25px;
        display: flex;
        justify-content: center;
        flex-flow: nowrap;
        position: relative;
        align-items: center;
        margin-bottom: 40px;

        .customer_t {
            font-size: 60px;
            font-weight: 700;
            color: #ff9900;
            margin: 0 20px;
        }

        .customer_wl_l {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .customer_wl_r {
            width: 123px;
            height: 31px;
            background: url(../assets/img/p2_title_wl.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }

    .customer_list_container {
        width: 100%;
        height: 520px;
        margin: 0px auto;
        background: #f6f6f6;
        padding-top: 60px;

        .swiper {
            width: 1608px;
            height: 500px;
            margin: 0 auto;
            // margin-top: 60px;
            // background: #ff9900;
            .swiper-wrapper {
                width: 1608px;
                height: 433px;
                position: absolute;
                top: 0px;
                left: 0px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .swiper-pagination {
                bottom: 0px;
            }

            .swiper-pagination-bullet-active {
                color: #ff9900;
                background-color: #ff9900;
            }
        }
    }
}

// 移动端样式
@media screen and (min-width: 320px) and(max-width: 750px) {
    .customer_container {
        width: 100%;
        height: 1314px;
        position: relative;
        background: #fff;
        overflow: hidden;

        .customer_title {
            padding-top: 125px;

            .customer_t {
                font-size: 76px;
                font-weight: 700;
                color: #ff9900;
                margin: 0 20px;
            }

            .customer_wl_l {
                width: 135px;
                height: 36px;
                background: url(../assets/img/p2_title_wl.png);
                background-size: 100%;
                background-repeat: no-repeat;
            }

            .customer_wl_r {
                width: 135px;
                height: 36px;
                background: url(../assets/img/p2_title_wl.png);
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }

        .customer_list_container {
            height: 1017px;
            width: 1920px;
            position: absolute;

            .swiper {
                height: 839px;

                .swiper-wrapper {
                    height: 703px;
                }

                .swiper-pagination {
                    margin-bottom: -70px;

                    .swiper-pagination-bullet {
                    }
                }
            }
        }
    }
}
</style>